












import Vue from "vue";
import StatisticsPage from "@/components/StatisticsPage.vue";

export default Vue.extend({
  components: { StatisticsPage },
  data() {
    return {
      month: parseInt(this.$route.params.month, 10),
    };
  },
});
